
import { defineComponent, ref, PropType } from 'vue'
import {
  useRouter,
  useRoute
} from 'vue-router'
import { soume } from '../tool/sou'
export default defineComponent({
  props: {
    mlist: Array as PropType<string[]>,
    title: String
  },
  emits: ['clickTab', 'search'],
  setup (props, context) {
    const active = ref<number>(0)
    const keyword = ref<string>('')
    const onClickTab = (e: any) => {
      console.log('eeeee', e)
      context.emit('clickTab', e.name)
    }
    const { souus } = soume(context)
    const $router = useRouter()
    const $route = useRoute()
    const soumew = () => {
      souus(keyword.value)
      // console.log('routerrrrrfwefwf', keyword.value)
      // if ($route.path === '/list') {
      //   console.log('soume')
      //   context.emit('search', keyword.value)
      // } else {
      //   $router.push('/list?keyword=' + keyword.value)
      // }
    }
    return {
      keyword,
      onClickTab,
      soumew,
      active
    }
  }
})
