import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../assets/img/b-sou.png'


const _withScopeId = n => (_pushScopeId("data-v-cd66a3fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "meuns" }
const _hoisted_2 = { class: "titl" }
const _hoisted_3 = { class: "names" }
const _hoisted_4 = { class: "soubox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_tab = _resolveComponent("van-tab")!
  const _component_van_tabs = _resolveComponent("van-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.keyword) = $event)),
          placeholder: "搜索关键词查询",
          class: "sous"
        }, null, 512), [
          [_vModelText, _ctx.keyword]
        ]),
        _createElementVNode("img", {
          src: _imports_0,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.soumew && _ctx.soumew(...args)))
        })
      ])
    ]),
    (_ctx.mlist && _ctx.mlist.length)
      ? (_openBlock(), _createBlock(_component_van_tabs, {
          key: 0,
          active: _ctx.active,
          "onUpdate:active": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.active) = $event)),
          color: "#FFA033",
          ellipsis: false,
          onClickTab: _ctx.onClickTab
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mlist, (item, i) => {
              return (_openBlock(), _createBlock(_component_van_tab, {
                title: item,
                key: i
              }, null, 8, ["title"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["active", "onClickTab"]))
      : _createCommentVNode("", true)
  ]))
}