
import mhead from '@/components/meunsh.vue'
import {
  useRoute
} from 'vue-router'
import { defineComponent, reactive, onMounted, toRefs, ref } from 'vue'
import { Toast } from 'vant'
import http from '@/api/index'
export default defineComponent({
  name: 'wens',
  components: { mhead },
  setup () {
    const $route = useRoute()
    const list:any = ref([])
    const mlist = ref([])
    const tlist = ref<any[]>([])
    const active = ref(0)
    const loading = ref(false)
    const finished = ref(false)
    let page = 1
    const clickTab = (inde: number) => {
      console.log('fufufuufuf', inde)
      active.value = inde
      page = 1
      list.value = []
      finished.value = false
      getwens()
    }
    const onLoad = () => {
      if (finished.value) return
      getwens()
    }
    const state = reactive({
      id: ''
    })
    const getmeun = async () => {
      loading.value = true
      const data:any = await http.get('portal/categories/subCategories', {
        category_id: state.id
      })
      if (data.code === 1) {
        tlist.value = data.data
        mlist.value = data.data.map((o:any) => {
          return o.name
        })
        getwens()
      } else {
        Toast(data.msg)
      }
    }
    const getwens = async () => {
      loading.value = true
      const id = tlist.value[active.value].id
      const data:any = await http.get('portal/lists/getCategoryPostLists', {
        category_id: id,
        page: page
      })
      if (data.code === 1) {
        list.value = [...list.value, ...data.data.list]
        loading.value = false
        page = page + 1
        if (page >= data.data.total_page) {
          finished.value = true
        }
      } else {
        Toast(data.msg)
      }
      // list.value = data.data
      // loading.value = false
      // finished.value = true
    }
    onMounted(() => {
      state.id = $route.query.id + ''
      getmeun()
    })
    return {
      ...toRefs(state),
      list,
      active,
      mlist,
      onLoad,
      getmeun,
      clickTab,
      loading,
      finished
    }
  }
})
