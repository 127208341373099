import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6abe6a1c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pages" }
const _hoisted_2 = { class: "anlist" }
const _hoisted_3 = { class: "items" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "ris" }
const _hoisted_6 = { class: "tops" }
const _hoisted_7 = { class: "time" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_myhead = _resolveComponent("myhead")!
  const _component_mhead = _resolveComponent("mhead")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_van_list = _resolveComponent("van-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_myhead, { sou: false }),
    _createVNode(_component_mhead, {
      mlist: _ctx.mlist,
      title: "工伤案例精选",
      onClickTab: _ctx.clickTab
    }, null, 8, ["mlist", "onClickTab"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_van_list, {
        loading: _ctx.loading,
        "onUpdate:loading": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.loading) = $event)),
        finished: _ctx.finished,
        "finished-text": "没有更多了",
        onLoad: _ctx.onLoad
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, i) => {
            return (_openBlock(), _createBlock(_component_router_link, {
              to: { path: '/info', query: { id: item.id}},
              key: i
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  (item.thumbnail)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: item.thumbnail,
                        class: "leimg"
                      }, null, 8, _hoisted_4))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(item.post_title), 1),
                    _createElementVNode("div", _hoisted_7, _toDisplayString(item.create_time), 1)
                  ])
                ])
              ]),
              _: 2
            }, 1032, ["to"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["loading", "finished", "onLoad"])
    ])
  ]))
}