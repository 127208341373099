
import {
  useRouter,
  useRoute
} from 'vue-router'

export const aa = 'gfe'
export function soume (context: any) {
  const $router = useRouter()
  const $route = useRoute()
  const souus = (keyword: string) => {
    console.log('jifewjifo11', keyword)
    console.log('touter', $route.path)
    if ($route.path === '/list') {
      context.emit('search', keyword)
    } else {
      $router.push('/list?keyword=' + keyword)
    }
  }
  return {
    souus
  }
}
